import { Injectable } from '@angular/core';
import { SmartChargingApiService } from '@api/smart-charging/smart-charging.api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from '@utils/Utils';
import {
  ChargingProfileEntity,
  ChargingProfileSchedulePeriodEntity,
} from '@repository/smart-charging/entity/charging-profile.entity';
import { ChargingProfile, ChargingSchedulePeriod } from '../../types/ChargingProfile';
import { ChargingStation } from '../../types/ChargingStation';

@Injectable()
export class SmartChargingRepositoryService {
  public constructor(private readonly smartChargingApiService: SmartChargingApiService) {}

  public getChargingProfiles(chargingStation: ChargingStation): Observable<ChargingProfileEntity[]> {
    return this.smartChargingApiService.getChargingProfiles({ chargingStationId: chargingStation.id }).pipe(
      map((response) => {
        return response.result.map((profile): ChargingProfileEntity => {
          profile.profile.chargingSchedule.startSchedule = new Date(profile.profile.chargingSchedule.startSchedule);
          return {
            ...profile,
            profile: {
              ...profile.profile,
              chargingSchedule: {
                ...profile.profile.chargingSchedule,
                periods: this.buildChargingSchedulePeriods(profile, chargingStation),
              },
            },
          };
        });
      }),
    );
  }

  private buildChargingSchedulePeriods(
    profile: ChargingProfile,
    chargingStation: ChargingStation,
  ): ChargingProfileSchedulePeriodEntity[] {
    return profile.profile.chargingSchedule.chargingSchedulePeriod.map(
      (period, index): ChargingProfileSchedulePeriodEntity => {
        const nextPeriod = profile.profile.chargingSchedule.chargingSchedulePeriod[index + 1];

        const startDate = this.getProfileStartDate(profile, period);
        const duration = this.getProfileDuration(profile, period, startDate, nextPeriod);
        const endDate = this.getProfileEndDate(startDate, duration);
        const chargePoint = Utils.getChargePointFromID(chargingStation, profile.chargePointID);
        const numberPhases = chargePoint.numberOfConnectedPhase;

        return {
          key: '',
          id: 0,
          startDate,
          endDate,
          duration,
          limit: period.limit,
          numberPhases,
          startPeriod: period.startPeriod,
          unit: profile.profile.chargingSchedule.chargingRateUnit,
        };
      },
    );
  }

  private getProfileEndDate(startDate: Date, duration: number): Date {
    return new Date(startDate.getTime() + duration * 60 * 1000);
  }

  private getProfileStartDate(profile: ChargingProfile, period: ChargingSchedulePeriod): Date {
    const startDate = new Date(profile.profile.chargingSchedule.startSchedule);
    startDate.setSeconds(startDate.getSeconds() + period.startPeriod);
    return startDate;
  }

  // Return the duration in minutes
  private getProfileDuration(
    profile: ChargingProfile,
    currentPeriod: ChargingSchedulePeriod,
    startDate: Date,
    nextPeriod?: ChargingSchedulePeriod,
  ): number {
    // If not the last period
    if (nextPeriod) {
      return (nextPeriod.startPeriod - currentPeriod.startPeriod) / 60;
    }

    // If it's the first period
    const profileStartDateMs = profile.profile.chargingSchedule.startSchedule.getTime() / 1000;
    const profileDuration = profile.profile.chargingSchedule.duration;
    const periodStartDateMs = startDate.getTime() / 1000;
    return (profileStartDateMs + profileDuration - periodStartDateMs) / 60;
  }
}
